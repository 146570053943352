import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { usePorfolioOutletContext } from '@pages/profile/ProfileTabs/components/Portfolios/usePortfolioOutletContext';
import { ContainerRow } from '../../styled';
import CurrentPositionsTable from '../tables/CurrentPositionsTable';
import EmptyBlock from '../EmptyBlock';
const Container = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const CurrentPositions = () => {
    const { portfolioId } = usePorfolioOutletContext();
    return (_jsxs(Container, { children: [_jsx(EmptyBlock, {}), _jsx(CurrentPositionsTable, { portfolioId: String(portfolioId) })] }));
};
export default CurrentPositions;
