import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { usePorfolioOutletContext } from '@pages/profile/ProfileTabs/components/Portfolios/usePortfolioOutletContext';
import PositionsHistoryTable from '../tables/PositionsHistoryTable';
import { ContainerRow } from '../../styled';
import EmptyBlock from '../EmptyBlock';
const Container = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const PositionsHistory = () => {
    const { portfolioId } = usePorfolioOutletContext();
    return (_jsxs(Container, { children: [_jsx(EmptyBlock, {}), _jsx(PositionsHistoryTable, { portfolioId: String(portfolioId) })] }));
};
export default PositionsHistory;
